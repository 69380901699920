import {
  makeStyles,
  Box,
  Container,
  Typography,
  TextField,
  FormHelperText,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import "react-phone-input-2/lib/style.css";
import Logo from "src/component/Logo";
import { dataPostHandler } from "src/apiConfig/service";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { toast } from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { UserContext } from "src/context/User";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  mainSignupFrom: {
    padding: "30px 0px",
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1111",
    paddingLeft: "50px",
    "& h4": {
      fontSize: "18px",
      marginBottom: "24px",
      // color:"#fff",
      "@media(max-width:768px)": {
        fontSize: "14px",
      },
    },
    "& .logoSection": {
      paddingBottom: "64px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    "@media(max-width:768px)": {
      paddingLeft: "0px",
    },
  },

  HeaddingBox: {
    marginTop: "5px",
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },
  formikBox: {
    marginTop: "15px",
  },

  Register: {
    "& h4": {
      // color: "#fff",
    },
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },

  FormInputField: {
    border: "none",
    color: "#fff",
    "& input": {
      marginTop: "8px",
      marginBottom: "4px",
      // color: "#fff",
    },

    "& .MuiOutlinedInput-input": {
      borderRadius: "0px",
      background: "none !important",
    },

    "& svg": {
      color: "#693993",
      cursor: "pointer",
    },
  },
}));

export default function ({ setSwapPanel, setOpenSignup, setOpenForgot }) {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  const themeSeeting = useContext(SettingsContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errmsg, setErrMsg] = useState("");
  const formInitialSchema = {
    email: "",
    password: "",
  };
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    password: yep.string().required("Password is required."),
  });
  const handleFormSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const response = await dataPostHandler("login", {
        email: values.email,
        password: values.password,
      });
      if (
        response?.data !== undefined &&
        response?.data?.statusCode !== undefined
      ) {
        if (response.data.statusCode == 200) {
          console.log("response0000", response);
          window.localStorage.setItem("token", response.data.result.token);
          window.localStorage.setItem(
            "userType",
            response.data.result.userType
          );
          user.getUserProfileDataHandler(response.data.result.token);
          setOpenSignup(false);
          // history.push("/profile");
          toast.success(response.data.responseMessage);
        } else {
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
        setErrMsg(response);
      }
    } catch (error) {
      console.log("error", error);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      className={
        themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
      }
    >
      <Box className={classes.mainSignupFrom}>
        <div className="shade13"></div>
        {/* <div
          className={
            themeSeeting?.settings?.theme === "DARK"
              ? "metaleftshadow"
              : "loginlightModal"
          }
        ></div>
        <div
          className={
            themeSeeting?.settings?.theme === "DARK"
              ? "metarightshadow"
              : "loginlightrightModal"
          }
        ></div> */}
        <Container maxWidth="lg">
          {/* <Box className="logoSection" onClick={() => history.push("/")}>
            <Logo />
          </Box> */}
          <Box className={classes.Register}>
            <Typography
              variant="h4"
              color="primary"
              style={{ fontSize: "30px" }}
            >
              Login
            </Typography>
          </Box>

          <Box className={classes.HeaddingBox}></Box>
          <Box className={classes.formikBox}>
            <Formik
              initialValues={formInitialSchema}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleFormSubmit(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        type="email"
                        placeholder="Email address"
                        fullWidth
                        name="email"
                        id="email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                      />

                      <FormHelperText error className={classes.helperText}>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        variant="standard"
                        fullWidth
                        name="password"
                        id="password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Box className={classes.passsec}>
                                  {showPassword ? (
                                    <VisibilityIcon
                                      className={classes.iconsclass1}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      className={classes.iconsclass1}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error className={classes.helperText}>
                        {touched.password && errors.password}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box mb={2} mt={2}>
                        {errmsg !== "" && (
                          <FormHelperText error>{errmsg}</FormHelperText>
                        )}
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{ marginTop: "2px" }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <>
                              Please wait... <ButtonCircularProgress />
                            </>
                          ) : (
                            "Login"
                          )}
                        </Button>
                      </Box>
                      <Box display="flex" justifyContent="center" width="100%">
                        <Typography
                          variant="h4"
                          style={{
                            textAlign: "center",
                            // fontSize: "18px",
                            cursor: "pointer",
                            marginRight: "12px",
                            marginBottom: "0px",
                            // color: "#fff",
                          }}
                          onClick={() => setSwapPanel(true)}
                        >
                          Sign up&nbsp; |
                        </Typography>
                        <Typography
                          variant="h4"
                          style={{
                            textAlign: "center",
                            // fontSize: "18px",
                            cursor: "pointer",
                            marginRight: "12px",
                            marginBottom: "0px",
                            // color: "#fff",
                          }}
                          onClick={() => {
                            setOpenSignup(false);
                            setOpenForgot(true);
                          }}
                        >
                          Forgot password?
                        </Typography>
                        {/* <Typography
                          variant="h4"
                          style={{
                            textAlign: "center",
                            textDecoration: "underline",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                          onClick={() => history.push("/")}
                        >
                          Home
                        </Typography> */}
                      </Box>

                      <div className="shade14"></div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
