import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Typography,
  withStyles,
  DialogTitle,
  DialogActions,
  Menu,
  Avatar,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Logo from './../../component/Logo'
import SettingsContext from 'src/context/SettingsContext'
import { BiSun, BiMoon } from 'react-icons/bi'
import Scroll from 'react-scroll'
import ConnectWallet from 'src/component/ConnectWallet'
import MuiDialogContent from '@material-ui/core/DialogTitle'
import MuiDialog from '@material-ui/core/Dialog'
import { useWeb3React } from '@web3-react/core'
import { UserContext } from 'src/context/User'
import Register from 'src/component/Register'
import MainLogin from 'src/component/MainLogin'
import Forgot from 'src/component/Forgot'
import Verify from 'src/component/Verify'
import Reset from 'src/component/Reset'
import { BsFillCaretDownFill } from 'react-icons/bs'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import EditIcon from '@material-ui/icons/Edit'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
const ScrollLink = Scroll.Link

const useStyles = makeStyles((theme) => ({
  profileText: {
    color: '#fff',
    '& h4': {
      color: '#fff',
    },
  },
  menuButton: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '300',
    borderRadius: 0,
    minWidth: 'auto',
    color: '#fff',
    height: '31px',
    padding: '0px 10px',
    letterSpacing: '1px',
    marginLeft: '10px',
    '@media (max-width: 900px)': {
      fontStyle: 'normal',
      letterSpacing: '-0.6px',
      lineHeight: '24px',
      color: '#FFF',
      padding: '15px !important',
      height: '51px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    '& .MuiMenu-list': {
      with: '193px !important',
    },
    '&.activeMenu': {
      background: 'linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      position: 'relative',
      '&:before': {
        content: "' '",
        position: 'absolute',
        top: '26px',
        left: ' 0px',
        width: '100%',
        height: '5px',
        background:
          'radial-gradient(50% 50% at 50% 50%, #FDA645 0%, rgba(255, 0, 205, 0) 100%)',
        [theme.breakpoints.only('xs')]: {
          display: 'none',
        },
      },
    },
    '& h6': {
      fontWeight: '400',
    },
    // "&:hover": {
    //   background: "#90909838",
    // },
  },
  mainSection: {
    background: '#1B0F20',
  },
  toolbar: {
    display: 'flex',
    padding: '6px 0',
    justifyContent: 'space-between',
    height: '100%',
    '@media (max-width: 900px)': {
      paddingLeft: '75px',
      paddingRight: '20px',
      height: '100%',
    },
  },

  drawerContainer: {
    padding: '20px 0px ',
    height: '100%',
    background: '#06010D',
    width: '290px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  drawericon: {
    color: '#000',
    position: 'absolute',
    top: '0px',
    right: '-10px',
    fontSize: '25px',
  },
  logoImg: {
    marginTop: '5px',
    width: '192px',
    objectFit: 'contain',
    '@media (max-width: 500px)': {
      width: '155px',
    },
  },
  containerHeight: {
    height: '100%',
  },
  appmain: {
    background: theme.palette.background.header,
    //     border: "none",
    position: 'fixed',
    backdropFilter: theme.palette.background.headerfilter,
  },
  bright: {
    width: '70px',
    height: '34px',
    margin: '0px 10px',
    backgroundColor: theme.palette.background.bright,
    borderRadius: '50px',
    backdropFilter: 'blur(15px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      width: '16px',
      color: '#fff',
    },
  },
  mainHeader: {
    justifyContent: 'space-between',
    // padding: "0px",
  },
  wallet: {
    margin: ' 0px 10px',
    padding: '9px 20px',
    fontSize: '14px',
    fontWeight: '500',
    '@media (max-width: 900px)': {
      marginTop: '12px',
      background: 'rgba(204, 35, 75, 0.1)',
      boxShadow:
        '0px 4px 4px rgb(0 0 0 / 25%), inset 0px 0px 8px #b61733, inset 0px 0px 35px #b51632',
      borderRadius: '50px',
    },
  },

  contentTitle: {
    '& .MuiPaper': {
      root: {
        background: 'rgba(0, 0, 0, 0.3) !important',
        backdropFilter: 'blur(125px) !important',
        borderRadius: '50px !important',
      },
    },
    '& .MuiDialogTitle': {
      root: {
        padding: '0px 15px 0px 0px !important',
      },
    },
  },
  activeBlock: {
    position: 'relative',
    color: '#b51632',
    fontWeight: 'bold',

    textDecorationThickness: '3px',
    '&::before': {
      top: '24px',
      width: 'calc(100% - 21px)',
      height: '2px',
      content: "''",
      position: 'absolute',
      background: '#ccc',
    },
  },
  flexbox: {
    display: 'flex',
    alignItems: 'center',
    '& h4': {
      color: '#fff',
    },
  },
  activeThemeButton: {
    padding: '6px 9px',
    background: '#0000005e',
  },
  normalThemeButton: {
    padding: '6px 9px',
  },
  nameSymbol: {
    // border: "1px solid #ccc",
    background: 'rgba(204, 35, 75, 0.1)',
    boxShadow:
      '0px 4px 4px rgb(0 0 0 / 25%), inset 0px 0px 8px #b61733, inset 0px 0px 35px #b51632',
    padding: '6px 5px',
    borderRadius: '100%',
    '& h4': {
      fontSize: '18px',
      fontWeight: '600',
    },
  },
  MenuItemshover: {
    padding: '6px 10px',
    '&:hover': {
      backgroundColor: '#9c162e',
    },
  },
}))

const DialogContent = withStyles((theme) => ({
  root: {},
}))(MuiDialogContent)
const Dialog = withStyles((theme) => ({}))(MuiDialog)
const StyledMenu = withStyles({
  paper: {
    marginTop: '2px',
  },
  '& ..MuiListItem-button': {
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#9c162e',
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))
export default function Header() {
  const classes = useStyles()
  const user = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const location = useLocation()
  const { account } = useWeb3React()
  const [openSignup, setOpenSignup] = useState(false)
  const [openLogin, setOpenLogin] = useState(false)
  const [openLogout, setOpenLogout] = useState(false)
  const [openForgot, setOpenForgot] = useState(false)
  const [openVerify, setVerify] = useState(false)
  const [openReset, setOpenReset] = useState(false)

  const handlescroll = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 2000, behavior: 'smooth' })
    } else {
      history.push('/')
    }
  }
  const handlescrollHolders = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 1000, behavior: 'smooth' })
    } else {
      history.push('/')
    }
  }
  const handlescrollTop = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      history.push('/')
    }
  }
  const handlescroll3 = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 3900, behavior: 'smooth' })
    } else {
      history.push('/')
    }
  }
  const handlescrollTeam = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 5000, behavior: 'smooth' })
    } else {
      history.push('/')
    }
  }
  const handlescrollblog = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 5650, behavior: 'smooth' })
    } else {
      history.push('/')
    }
  }

  const {
    flexbox,
    menuButton,
    toolbar,
    profileText,
    drawerContainer,
    drawericon,
    containerHeight,
    appmain,
    bright,
    mainHeader,
    wallet,
    contentTitle,
    activeBlock,
    activeThemeButton,
    normalThemeButton,
    nameSymbol,
    MenuItemshover,
  } = useStyles()
  const history = useHistory()
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  })
  const { mobileView, drawerOpen } = state
  const themeSeeting = useContext(SettingsContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1224
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
  }, [])
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    })
  }
  const [activeSet, setActiveSet] = useState('Home')
  useEffect(() => {
    if (location.pathname === '/') {
      setActiveSet('Home')
    } else if (location.pathname === '/live-class') {
      setActiveSet('class')
    } else if (location.pathname === '/institute') {
      setActiveSet('institute')
    } else if (location.pathname === '/plot') {
      setActiveSet('plot')
    } else if (location.pathname === '/profile') {
      setActiveSet('profile')
    } else if (location.pathname === '/blog-list') {
      setActiveSet('Blog')
    } else {
      setActiveSet('')
    }
  }, [location])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  function nameSybmolCreator(first, last) {
    const firstName = first?.slice(0, 1)
    const lastName = last?.slice(0, 1)
    const shortForm = `${firstName}${lastName}`
    return shortForm
  }
  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: '0px' }}
          >
            <nav>
              <ul className="navigation">
                <li>
                  <MenuItem
                    exact
                    activeClassName="activeMenu"
                    className={[
                      menuButton,
                      activeSet === 'Home' ? activeBlock : null,
                    ]}
                    onClick={() => {
                      handlescrollTop()
                      setActiveSet('Home')
                    }}
                  >
                    Home
                  </MenuItem>
                </li>
                {!window.localStorage.getItem('token') && (
                  <>
                    <li>
                      <MenuItem
                        exact
                        activeClassName="activeMenu"
                        className={[
                          menuButton,
                          activeSet === 'Stakeholders' ? activeBlock : null,
                        ]}
                        onClick={() => {
                          handlescrollHolders()
                          setActiveSet('Stakeholders')
                        }}
                      >
                        Stakeholders
                      </MenuItem>
                    </li>
                    <li>
                      <MenuItem
                        exact
                        activeClassName="activeMenu"
                        className={[
                          menuButton,
                          activeSet === 'Tokenomics' ? activeBlock : null,
                        ]}
                        onClick={() => {
                          handlescroll()
                          setActiveSet('Tokenomics')
                        }}
                      >
                        Tokenomics
                      </MenuItem>
                    </li>

                    <li>
                      {' '}
                      <MenuItem
                        exact
                        className={[
                          menuButton,
                          activeSet === 'Roadmap' ? activeBlock : null,
                        ]}
                        onClick={() => {
                          handlescroll3()
                          setActiveSet('Roadmap')
                        }}
                      >
                        Roadmap
                      </MenuItem>
                    </li>
                    <li>
                      {' '}
                      <MenuItem
                        exact
                        className={[
                          menuButton,
                          activeSet === 'Team' ? activeBlock : null,
                        ]}
                        onClick={() => {
                          handlescrollTeam()
                          setActiveSet('Team')
                        }}
                      >
                        Team
                      </MenuItem>
                    </li>
                  </>
                )}
                {(window.localStorage.getItem('userType') === 'INSTITUTE' ||
                  window.localStorage.getItem('userType') === 'STUDENT') && (
                  <li>
                    <MenuItem
                      className={[
                        menuButton,
                        activeSet === 'class' ? activeBlock : null,
                      ]}
                      onClick={() => history.push('/live-class')}
                    >
                      Courses
                    </MenuItem>
                  </li>
                )}
                {window.localStorage.getItem('userType') === 'INSTITUTE' && (
                  <li>
                    <MenuItem
                      className={[
                        menuButton,
                        activeSet === 'institute' ? activeBlock : null,
                      ]}
                      onClick={() => history.push('/institute')}
                    >
                      Institute
                    </MenuItem>
                  </li>
                )}
                {(window.localStorage.getItem('userType') === 'INSTITUTE' ||
                  window.localStorage.getItem('userType') === 'STUDENT') && (
                  <li>
                    <MenuItem
                      className={[
                        menuButton,
                        activeSet === 'plot' ? activeBlock : null,
                      ]}
                      onClick={() => history.push('/plot')}
                    >
                      Plot
                    </MenuItem>
                  </li>
                )}
                <li>
                  {' '}
                  <MenuItem
                    className={[
                      menuButton,
                      activeSet === 'Blog' ? activeBlock : null,
                    ]}
                    onClick={() => {
                      // handlescrollblog();
                      history.push('/blog-list')
                      setActiveSet('Blog')
                    }}
                  >
                    Blog
                  </MenuItem>
                </li>
                {window.localStorage.getItem('token') && (
                  <IconButton
                    className={[menuButton]}
                    aria-label="delete"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    // className={menuMobile1}

                    size="small"
                  >
                    {/* <Avatar
                      // style={{ objectFit:"cover" }}
                      src={
                        user?.userData?.profilePic !== ""
                          ? user?.userData?.profilePic
                          : `https://avatars.dicebear.com/api/identicon/${window.localStorage.getItem(
                              "token"
                            )}.svg`
                      }
                    /> */}
                    <Box className={nameSymbol}>
                      <Typography variant="h4">
                        {user?.userData?.firstName &&
                          user?.userData?.lastName &&
                          nameSybmolCreator(
                            user?.userData?.firstName,
                            user?.userData?.lastName,
                          )}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      style={{ fontSize: '14px', marginLeft: '7px' }}
                      className="profileText"
                    >
                      {user?.userData?.firstName &&
                        user?.userData?.lastName &&
                        `${user?.userData?.firstName} ${user?.userData?.lastName}`}
                    </Typography>
                    <BsFillCaretDownFill
                      style={{ color: '#fff', fontSize: '16px' }}
                    />
                  </IconButton>
                )}
              </ul>
            </nav>
            {!window.localStorage.getItem('token') && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={wallet}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => setOpenSignup(true)}
                >
                  Get Started
                </Button>
              </>
            )}

            {/* {window.localStorage.getItem("token") && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={wallet}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => setOpenLogout(true)}
              >
                Logoutx
              </Button>
            )} */}
            {themeToggleButton}
          </Grid>
          <Box>
            <StyledMenu
              id="simple-menu"
              disableScrollLock={true}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={
                themeSeeting?.settings?.theme === 'LIGHT'
                  ? 'poperForMenu1'
                  : 'poperForMenu'
              }
            >
              <MenuItem
                className={MenuItemshover}
                onClick={() => {
                  handleClose()
                  history.push('/profile')
                }}
              >
                <Box className={flexbox}>
                  <AccountBoxIcon
                    style={{ marginRight: '8px', color: '#fff' }}
                  />
                  <Typography
                    variant="h4"
                    style={{ fontSize: '13px' }}
                    className="profileText"
                  >
                    Profile
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem
                className={MenuItemshover}
                onClick={() => {
                  handleClose()
                  history.push('/upload-profile')
                }}
              >
                <Box className={flexbox}>
                  <EditIcon style={{ marginRight: '8px', color: '#fff' }} />
                  <Typography
                    variant="h4"
                    style={{ fontSize: '13px' }}
                    className="profileText"
                  >
                    Edit Profile
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem
                className={MenuItemshover}
                onClick={() => {
                  handleClose()
                  setOpenLogout(true)
                }}
              >
                <Box className={flexbox}>
                  <ExitToAppIcon
                    style={{ marginRight: '8px', color: '#fff' }}
                  />
                  <Typography
                    variant="h4"
                    style={{ fontSize: '13px' }}
                    className="profileText"
                  >
                    Logout
                  </Typography>
                </Box>
              </MenuItem>
            </StyledMenu>
          </Box>
        </Toolbar>
      </Container>
    )
  }
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }))

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: 'right',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <Box className="drawer2"></Box>
            {getDrawerChoices(handleDrawerClose)}
            <Box width="100%">
              {!window.localStorage.getItem('token') && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={wallet}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => setOpenSignup(true)}
                  >
                    Get Started
                  </Button>
                </>
              )}

              {window.localStorage.getItem('token') && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={wallet}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => setOpenLogout(true)}
                >
                  Logout
                </Button>
              )}
            </Box>
          </div>
        </Drawer>
        <Grid
          container
          style={{
            alignItems: 'center',
          }}
        >
          <Grid item xs={6} sm={8} md={10}>
            {' '}
            <div>{femmecubatorLogo}</div>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {themeToggleButton}
              <IconButton
                className={drawericon}
                {...{
                  edge: 'start',
                  color: 'inherit',
                  'aria-label': 'menu',
                  'aria-haspopup': 'true',
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: '#7C162E', fontSize: '30px' }}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    )
  }
  const getDrawerChoices = (handleDrawerClose) => {
    return (
      <>
        <nav>
          <input type="checkbox" id="check" />
          <label clasnames="logo">
            <img
              src="/images/drawerlogo.png"
              alt="Logo"
              width="100%"
              style={{ width: '100%', maxWidth: '150px' }}
            />
          </label>
          <ul className="navigation">
            <ScrollLink
              onClick={() => {
                handlescrollTop()
                handleDrawerClose() //callbackFunction
              }}
              className="hovertext"
              smooth={true}
              duration={500}
              to="tokenomics"
              style={{ cursor: 'pointer' }}
            >
              <Typography
                variant="h6"
                paragraph
                className={`${classes.typosec} hovertext`}
              >
                Home
              </Typography>
            </ScrollLink>
            {!window.localStorage.getItem('token') && (
              <>
                <ScrollLink
                  onClick={() => {
                    handlescrollHolders()
                    handleDrawerClose() //callbackFunction
                  }}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  to="Stakeholders"
                  style={{ cursor: 'pointer' }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    Stakeholders
                  </Typography>
                </ScrollLink>
                <ScrollLink
                  onClick={() => {
                    handlescroll()
                    handleDrawerClose() //callbackFunction
                  }}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  to="tokenomics"
                  style={{ cursor: 'pointer' }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    Tokenomics
                  </Typography>
                </ScrollLink>

                <ScrollLink
                  onClick={() => {
                    handlescroll3()
                    handleDrawerClose() //callBackFunction
                  }}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  to="roadmap"
                  style={{ cursor: 'pointer' }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    Roadmap
                  </Typography>
                </ScrollLink>

                <ScrollLink
                  onClick={() => {
                    handlescrollTeam()
                    handleDrawerClose() //callBackFunction
                  }}
                  className="hovertext"
                  smooth={true}
                  duration={500}
                  to="roadmap"
                  style={{ cursor: 'pointer' }}
                >
                  <Typography
                    variant="h6"
                    paragraph
                    className={`${classes.typosec} hovertext`}
                  >
                    Team
                  </Typography>
                </ScrollLink>
              </>
            )}

            {(window.localStorage.getItem('userType') === 'INSTITUTE' ||
              window.localStorage.getItem('userType') === 'STUDENT') && (
              <ScrollLink
                onClick={() => history.push('/live-class')}
                className="hovertext"
                smooth={true}
                duration={500}
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  variant="h6"
                  paragraph
                  className={`${classes.typosec} hovertext`}
                >
                  Courses
                </Typography>
              </ScrollLink>
            )}

            {window.localStorage.getItem('userType') === 'INSTITUTE' && (
              <ScrollLink
                onClick={() => history.push('/institute')}
                className="hovertext"
                smooth={true}
                duration={500}
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  variant="h6"
                  paragraph
                  className={`${classes.typosec} hovertext`}
                >
                  Institute
                </Typography>
              </ScrollLink>
            )}
            {(window.localStorage.getItem('userType') === 'INSTITUTE' ||
              window.localStorage.getItem('userType') === 'STUDENT') && (
              <ScrollLink
                onClick={() => history.push('/plot')}
                className="hovertext"
                smooth={true}
                duration={500}
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  variant="h6"
                  paragraph
                  className={`${classes.typosec} hovertext`}
                >
                  Plot
                </Typography>
              </ScrollLink>
            )}
            <ScrollLink
              onClick={() => {
                history.push('/blog-list')
                handleDrawerClose() //callBackFunction
              }}
              className="hovertext"
              smooth={true}
              duration={500}
              to="Blog"
              style={{ cursor: 'pointer' }}
            >
              <Typography
                variant="h6"
                paragraph
                className={`${classes.typosec} hovertext`}
              >
                Blog
              </Typography>
            </ScrollLink>

            {window.localStorage.getItem('token') && (
              <ScrollLink
                onClick={() => history.push('/profile')}
                className="hovertext"
                smooth={true}
                duration={500}
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  variant="h6"
                  paragraph
                  className={`${classes.typosec} hovertext`}
                >
                  Profile
                </Typography>
              </ScrollLink>
            )}
          </ul>
        </nav>
      </>
    )
  }
  const femmecubatorLogo = (
    <Box onClick={handlescrollTop}>
      <Link to="/">
        <Logo className={classes.logoImg} />
      </Link>
    </Box>
  )
  const themeToggleButton = (
    <Box className={bright}>
      <IconButton
        className={
          themeSeeting?.settings?.theme === 'LIGHT'
            ? activeThemeButton
            : normalThemeButton
        }
        onClick={() => {
          changeTheme('LIGHT')
        }}
      >
        <BiSun />
      </IconButton>
      <IconButton
        className={
          themeSeeting?.settings?.theme === 'DARK'
            ? activeThemeButton
            : normalThemeButton
        }
        onClick={() => {
          changeTheme('DARK')
        }}
      >
        <BiMoon />
      </IconButton>
    </Box>
  )
  useEffect(() => {
    if (account) {
      user.setIsOpenMetaMaskOpen(false)
    }
  }, [account])

  return (
    <>
      <AppBar
        position={history.location.pathname !== '/' ? 'relative' : 'absolute'}
        elevation={0}
        className={appmain}
      >
        <Box
          maxWidth={history.location.pathname !== '/' ? 'lg' : 'fixed'}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>

      {/* CONNECT MODAL*/}
      <Dialog
        open={user?.isMetaMaskOpen}
        onClose={() => {
          user.setIsOpenMetaMaskOpen(false)
        }}
        maxWidth="lg"
        className={contentTitle}
      >
        <ConnectWallet
          onClose={() => {
            user.setIsOpenMetaMaskOpen(false)
          }}
          account={account}
        />
      </Dialog>

      {/* SINGUP MODAL*/}
      <Dialog
        open={openSignup || user?.openRegister}
        onClose={() => {
          setOpenSignup(false)
          user.setOpenRegister(false)
        }}
        maxWidth="md"
      >
        <Register
          setOpenSignup={(data) => setOpenSignup(data)}
          setOpenForgot={(data) => setOpenForgot(data)}
          user={user}
        />
      </Dialog>

      {/* LOGIN MODAL*/}
      <Dialog
        open={openLogin}
        onClose={() => {
          setOpenLogin(false)
        }}
        maxWidth="md"
      >
        <MainLogin
          setOpenLogin={(data) => setOpenLogin(data)}
          setOpenForgot={(data) => setOpenForgot(data)}
          setOpenSignup={(data) => setOpenSignup(data)}
          themeSeeting={themeSeeting}
        />
      </Dialog>

      {/* FORGOT_PASSWORD MODAL*/}
      <Dialog
        open={openForgot}
        onClose={() => {
          setOpenForgot(false)
        }}
        maxWidth="md"
      >
        <Forgot
          setOpenForgot={(data) => setOpenForgot(data)}
          setOpenLogin={(data) => setOpenSignup(data)}
          setVerify={(data) => setVerify(data)}
          themeSeeting={themeSeeting}
        />
      </Dialog>

      {/* VERIFY_OTP MODAL*/}
      <Dialog
        open={openVerify}
        onClose={() => {
          setVerify(false)
        }}
        maxWidth="md"
      >
        <Verify
          setVerify={(data) => setVerify(data)}
          setOpenReset={(data) => setOpenReset(data)}
          themeSeeting={themeSeeting}
        />
      </Dialog>

      {/* RESET_PASSWORD MODAL*/}
      <Dialog
        open={openReset}
        onClose={() => {
          setOpenReset(false)
        }}
        maxWidth="md"
      >
        <Reset
          setOpenReset={(data) => setOpenReset(data)}
          setOpenLogin={(data) => setOpenLogin(data)}
          themeSeeting={themeSeeting}
        />
      </Dialog>

      {/* LOGOUT MODAL*/}
      <Dialog open={openLogout} onClose={() => setOpenLogout(false)}>
        <Box className="newModalBorder">
          <Box
            className={
              themeSeeting?.settings?.theme === 'DARK' ? 'mainbox' : 'mainbox1'
            }
          >
            <Box style={{ position: 'relative', zIndex: '1' }}>
              <div className="shade13"></div>
              <DialogTitle>
                <Typography variant="h3" color="primary">
                  Logout!
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography
                  variant="h4"
                  color="primary"
                  style={{ whiteSpace: 'pre' }}
                >
                  Do you want to logout?
                </Typography>
                <Box mt={2} mb={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setOpenLogout(false)}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: '7px' }}
                    onClick={() => {
                      setOpenLogout(false)
                      user.disconnectWallet()
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </DialogContent>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
