import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  Button,
  Divider,
  TextField,
  FormHelperText,
  IconButton,
  Link,
  MenuItem,
} from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import SettingsContext from "src/context/SettingsContext";
import { RiInstagramLine } from "react-icons/ri";
import { RiDiscordFill } from "react-icons/ri";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Form, Formik } from "formik";
import * as yep from "yup";
import axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  baseSection: {
    display: "flex",
    justifyContent: "space-between",
    "& p": {
      color: theme.palette.text.textfooter,
      fontSize: "16px",
      fontWeight: "400",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.text.dull,
    },
    "@media(max-width:565px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  privacy: {
    display: "flex",
    "@media(max-width:565px)": {
      marginTop: "1.5rem",
    },
  },
  icon: {
    margin: "0px 4px",
    color: theme.palette.primary.main,
    cursor: "pointer",
    width: "14px",
    "&:hover": {
      color: "#9c162e",
    },
  },

  listitem: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#B7B7B7",
    cursor: "pointer",
    background: "none",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    "@media(max-width:599px)": {
      fontSize: "14px",
    },
  },

  subpart: {
    background: theme.palette.background.footerbg,
    backdropFilter: "blur(20px)",
    position: "relative",
    zIndex: 1,
    padding: "60px 0px 40px",
    overflow: "hidden",

    "& h6": {
      fontSize: "16px",
      color: theme.palette.text.primary,
      fontWeight: "400 !important",
      lineHeight: "21px",
      width: "100%",
      maxWidth: "238px",
      marginBottom: "12px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        width: "100%",
        maxWidth: "100%",
        marginBottom: "10px",
      },
    },
    "@media(max-width:599px)": {
      padding: "20px 10px 24px 10px",
    },
    "& h2": {
      fontSize: "35px",
      fontWeight: "500",
      lineHeight: "46px",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "26px",
        lineHeight: "31px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px !important",
        marginBottom: "0px",
      },
    },
  },

  listbox: {
    background: "none",
    border: "none",
    margin: "0px",
    "& a": {
      color: theme.palette.text.primary,
      width: "fit-content",
      display: "block",
      fontSize: "16px",
      fontWeight: "400",
      paddingTop: "4px",
      fontFamily: "'Roboto', sans-serif",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
      "&:hover": {
        color: "#9c162e",
      },
    },
  },
  contactbox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-10px",
    marginLeft: "-10px",
    marginTop: "4px",
    "& a": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontWeight: "400",
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    "& button": {
      "& svg": {
        colot: theme.palette.primary.main,
      },
    },
    "& svg": {
      color: "#3F3E41",
      fontSize: "18px",
    },
  },
  leftbox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "130px",
    "& .dot": {
      height: "24px",
      width: "24px",
      borderRadius: "50%",
      backgroundColor: "rgba(81, 40, 135, 0.25)",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "6px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "16px",
    },
  },
  buttonbox: {
    marginTop: "10px",
    "& button": {
      fontSize: "16px",
      fontWeight: "300",
      color: "#fff",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
      background: "rgba(66, 66, 66, 0.2)",
      boxShadow: "0px 10px 15px rgb(0 0 0 / 25%)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(255 255 255 / 0%)",
    },
  },

  Hover: {
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "400",
    color: theme.palette.primary.main,
    "&:hover": {
      color: "#9c162e",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const themeSeeting = useContext(SettingsContext);
  const [isLoading, setIsLoading] = useState(false);
  const formInitialSchema = {
    email: "",
  };
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .required("Email is required."),
  });

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await axios({
        method: "POST",
        url: Apiconfigs.userSubscribe,
        data: {
          email: values.email,
        },
      });
      if (response) {
        toast.success("You have subscribed successfully.");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handlescroll3 = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 2000, behavior: "smooth" });
    } else {
      history.push("/");
    }
  };
  const handlescroll4 = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 2800, behavior: "smooth" });
    } else {
      history.push("/");
    }
  };
  return (
    <>
      <Box className={classes.subpart}>
        <Box
          className={
            themeSeeting?.settings?.theme === "DARK" ? "footer" : "footer1"
          }
        >
          <div
            className={
              themeSeeting?.settings?.theme === "DARK"
                ? "footerleftDark"
                : "footerleftLight"
            }
          ></div>
          <div
            className={
              themeSeeting?.settings?.theme === "DARK"
                ? "footerrightDark"
                : "footerrightLight"
            }
          ></div>
          {/* <div className="shade1"></div>
          <div className="shade2"></div> */}
          <Container>
            <Box>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box>
                    {themeSeeting?.settings?.theme === "DARK" ? (
                      <img
                        src="/images/logo.png"
                        alt=""
                        width="100%"
                        style={{ maxWidth: "192px", margin: "0 auto" }}
                      />
                    ) : (
                      <>
                        <img
                          src="/images/lighthemeLogo.png"
                          alt=""
                          width="100%"
                          style={{ maxWidth: "192px", margin: "0 auto" }}
                        />
                      </>
                    )}
                  </Box>{" "}
                  <Box mt={1} mb={1}>
                    <Typography variant="h6">
                      Hexaverse provides a metaverse-based education system that
                      is integrated between educator and learner. We are
                      providing largest Ed-NFT platform for all kinds of
                      institutes Like Science, MBA, Engineering, and so on.
                    </Typography>{" "}
                  </Box>
                  <Box className={classes.leftbox}>
                    <Link target="_blank" href="https://twitter.com/">
                      <Box className="dot">
                        <TwitterIcon className={classes.icon} />
                      </Box>
                    </Link>
                    <Link target="_blank" href="https://www.instagram.com/">
                      <Box className="dot">
                        <RiInstagramLine className={classes.icon} />
                      </Box>
                    </Link>
                    <Link target="_blank" href="https://www.linkedin.com/">
                      <Box className="dot">
                        <LinkedInIcon className={classes.icon} />
                      </Box>
                    </Link>
                    <Link target="_blank" href="https://www.discord.com/">
                      <Box className="dot">
                        <RiDiscordFill className={classes.icon} />
                      </Box>
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={3} lg={3}>
                      <Typography variant="h4" color="primary">
                        Quick Links
                      </Typography>
                      <List className={classes.listbox}>
                        <RouterLink
                          className="hovertext"
                          to="/about-us"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItem>About Us</ListItem>
                        </RouterLink>
                        {/* <RouterLink
                          className="hovertext"
                          to="/"
                          style={{ textDecoration: "none" }}
                        > */}
                        <ListItem
                          className={classes.Hover}
                          onClick={handlescroll3}
                        >
                          Tokenomics
                        </ListItem>
                        {/* </RouterLink> */}
                        {/* <RouterLink
                          className="hovertext"
                          to="/"
                          style={{ textDecoration: "none" }}
                        > */}
                        <ListItem
                          className={classes.Hover}
                          onClick={handlescroll4}
                        >
                          Token Distribution
                        </ListItem>
                        {/* </RouterLink> */}
                        <RouterLink
                          className="hovertext"
                          to="/blog-list"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItem>Blog</ListItem>
                        </RouterLink>
                        <RouterLink
                          className="hovertext"
                          to="/contact-us"
                          style={{ textDecoration: "none" }}
                        >
                          <ListItem>Contact Us</ListItem>
                        </RouterLink>
                      </List>{" "}
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={4}>
                      <Typography variant="h4" color="primary">
                        Contact Us
                      </Typography>
                      <Box className={classes.contactbox}>
                        <Box style={{ marginTop: "2px", marginRight: "5px" }}>
                          <AiOutlineMail />
                        </Box>
                        <Link>
                          <a href="mailto:Hello@hexaverses.com">
                            Hello@hexaverses.com
                          </a>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                      <Formik
                        initialValues={formInitialSchema}
                        initialStatus={{
                          success: false,
                          successMsg: "",
                        }}
                        validationSchema={formValidationSchema}
                        onSubmit={(values) => handleFormSubmit(values)}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          touched,
                          values,
                          setFieldValue,
                        }) => (
                          <Form className={classes.root}>
                            <Box className={classes.textField}>
                              <Typography variant="h2" color="primary">
                                Subscribe for our newsletter.
                              </Typography>
                              <TextField
                                placeholder="Enter your email address.."
                                variant="outlined"
                                name="email"
                                id="email"
                                value={values.email}
                                error={Boolean(touched.email && errors.email)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              />

                              <FormHelperText
                                error
                                className={classes.helperText}
                              >
                                {touched.email && errors.email}
                              </FormHelperText>
                            </Box>
                            <Box className={classes.buttonbox}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <>
                                    Please wait...
                                    <ButtonCircularProgress />
                                  </>
                                ) : (
                                  "Join now"
                                )}
                              </Button>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Box pt={5} pb={4}>
            <Divider />
          </Box>
          <Container>
            <Box className={classes.baseSection}>
              <Typography variant="body1" color="secondary">
                © 2022 All Rights Reserved
              </Typography>
              <Box className={classes.privacy}>
                <RouterLink to="/privacy-policy">
                  <Typography variant="body1">Privacy Policy |</Typography>
                </RouterLink>
                &nbsp;
                <RouterLink to="/term">
                  <Typography variant="body1">
                    Terms and Conditions |
                  </Typography>
                </RouterLink>
                &nbsp;
                <RouterLink to="/contact-us">
                  <Typography variant="body1">Token Disclaimer</Typography>
                </RouterLink>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
